<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/activity-branches" />
        </v-col>
      </v-row>

      <activity-branches-fields
        :loading-save="loadingSave"
        :loading-get="loadingGet"
        :form-validation="formValidation"
        :activity-branch="activityBranch"
        @save="save"
      />
    </v-container>
  </div>
</template>

<script>
  import activityBranchesApi from '@/api/activity-branches'

  export default {

    components: {
      ActivityBranchesFields: () => import('./ActivityBranchesFields'),
    },

    data () {
      return {
        loadingSave: false,
        loadingGet: false,
        formValidation: {},
        activityBranch: {},
      }
    },

    mounted () {
      this.get()
    },

    methods: {
      async get () {
        try {
          this.loadingGet = true

          const response = await activityBranchesApi.get(this.$route.params.id)

          this.activityBranch = response.data.branch
        } catch (e) {
          this.$router.push('/activity-branches', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      async save (activityBranch) {
        try {
          this.loadingSave = true

          await activityBranchesApi.update(activityBranch.id, activityBranch)

          this.$router.push('/activity-branches', () => this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') }))
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
